<template>
  <div id="view-project">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-card
            class="mx-auto"
            style="min-width: 60vw;"
          >
            <v-card-title primary-title>
              <div>
                <h3 class="headline mb-0">Project</h3>
              </div>
            </v-card-title>
            <v-form
              ref="form"
              class="pa-3 pt-4"
            >
              <v-text-field
                v-model="project_id"
                filled
                label="Project Id"
                type="text"
                disabled
              ></v-text-field>
              <v-text-field
                v-model="name"
                filled
                label="Project Name"
                type="text"
                disabled
              ></v-text-field>
              <v-textarea
                v-model="project_description"
                auto-grow
                filled
                label="Project Description"
                rows="1"
                disabled
              ></v-textarea>
              <v-textarea
                v-model="project_image_url"
                auto-grow
                filled
                label="Project Image URL"
                rows="1"
                disabled
              ></v-textarea>
              <v-text-field
                v-model="approved"
                filled
                label="Approved"
                type="text"
                disabled
              ></v-text-field>
              <v-text-field
                v-model="added"
                filled
                label="Added Date"
                type="text"
                disabled
              ></v-text-field>
              <v-text-field
                v-model="added_by"
                filled
                label="Added By"
                type="text"
                disabled
              ></v-text-field>
              <v-text-field
                v-model="edited"
                filled
                label="Last Edited"
                type="text"
                disabled
              ></v-text-field>
              <v-chip-group
                multiple
                column
                active-class="primary--text">
                <v-tooltip
                  top
                  v-for="link in this.project_links"
                  v-bind:key="link.project_url"
                >
                  <template v-slot:activator="{ on }">
                    <v-chip
                      class="mr-2"
                      v-on="on"
                      :href="link.project_url"
                      target="_blank"
                    >
                      {{link.project_label}}
                    </v-chip>
                  </template>
                  <span>{{link.project_url}}</span>
                </v-tooltip>
              </v-chip-group>
            </v-form>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="primary" v-bind:to="{ name: 'edit-project', params: { project_id: project_id }}">Edit
              </v-btn>
              <v-btn color="info" to="/projects">Back
              </v-btn>
              <v-btn color="danger" v-on:click="deleteProject">Delete</v-btn>
            </v-card-actions>
          </v-card>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import db from '../config/firebaseInit'

export default {
  name: 'view-project',
  data () {
    return {
      project_id: null,
      name: null,
      project_description: null,
      project_image_url: null,
      project_links: null,
      approved: null,
      added: null,
      edited: null,
      added_by: null
    }
  },
  beforeRouteEnter (to, from, next) {
    db
      .collection('projects')
      .where('project_id', '==', to.params.project_id)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          next(vm => {
            vm.project_id = doc.data().project_id
            vm.name = doc.data().name
            vm.project_description = doc.data().project_description
            vm.project_image_url = doc.data().project_image_url
            vm.project_links = doc.data().project_links
            vm.approved = doc.data().approved
            vm.added = doc.data().added.toDate().toDateString()
            vm.edited = doc.data().edited.toDate().toDateString()
            vm.added_by = doc.data().added_by
          })
        })
      })
  },
  watch: {
    $route: 'fetchData'
  },
  methods: {
    fetchData () {
      db
        .collection('projects')
        .where('project_id', '==', this.$route.params.project_id)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.project_id = doc.data().project_id
            this.name = doc.data().name
            this.project_description = doc.data().project_description
            this.project_image_url = doc.data().project_image_url
            this.project_links = doc.data().project_links
            this.approved = doc.data().approved
            this.added = doc.data().added.toDate().toDateString()
            this.edited = doc.data().edited.toDate().toDateString()
            this.added_by = doc.data().added_by
          })
        })
    },
    deleteProject () {
      if (confirm('Are you sure?')) {
        db
          .collection('projects')
          .where('project_id', '==', this.$route.params.project_id)
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              doc.ref.delete()
              this.$router.push('/projects')
            })
          })
      }
    }
  }
}
</script>
