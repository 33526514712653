<template>
  <div id="view-project">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-card
            class="mx-auto"
            style="min-width: 60vw;"
          >
            <v-card-title primary-title>
              <div>
                <h3 class="headline mb-0">Short Link</h3>
              </div>
            </v-card-title>
            <v-form
              ref="form"
              class="pa-3 pt-4"
            >
              <v-text-field
                v-model="short_link_id"
                filled
                label="Short Link Id"
                type="text"
                disabled
              ></v-text-field>
              <v-text-field
                v-model="short_link_title"
                filled
                label="Short Link Title"
                type="text"
                disabled
              ></v-text-field>
              <v-textarea
                v-model="short_link_slashtag"
                auto-grow
                filled
                label="Slashtag"
                rows="1"
                disabled
              ></v-textarea>
              <v-text-field
                v-model="short_link_destination"
                filled
                label="Approved"
                type="text"
                disabled
              ></v-text-field>
            </v-form>
            <v-divider></v-divider>
            <v-card-actions>
<!--              <v-btn color="primary" v-bind:to="{ name: 'edit-project', params: { project_id: project_id }}">Edit-->
<!--              </v-btn>-->
              <v-btn color="info" to="/short-links">Back
              </v-btn>
              <v-btn color="danger" v-on:click="deleteShortLink">Delete</v-btn>
            </v-card-actions>
          </v-card>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'view-project',
  data () {
    return {
      short_link_id: this.$route.params.short_link_id,
      short_link_slashtag: null,
      short_link_title: null,
      short_link_destination: null,
    }
  },
  async created () {
    let rawData = await axios
      .get('https://api.euanriggans.com/rebrandly/get-all/?panel_key=JKM7tTgMGTK8Qq7wAki8pTqmfE6iMe')
      .then((response) => {
        for(let shortlink of response.data) {
          if(shortlink.id === this.short_link_id) {
            rawData = shortlink;
          }
        }
        return rawData;
      });
    this.short_link_id = rawData.id;
    this.short_link_slashtag = rawData.slashtag;
    this.short_link_title = rawData.title;
    this.short_link_destination = rawData.destination;
  },
  methods: {
    async deleteShortLink () {
      if (confirm('Are you sure?')) {
        await axios
          .get('https://api.euanriggans.com/rebrandly/delete-link/?panel_key=JKM7tTgMGTK8Qq7wAki8pTqmfE6iMe&linkid=' + this.short_link_id)
          .then(() => {
            this.$router.push('/short-links')
          });
      }
    }
  }
}
</script>
