<template>
  <div>
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-card class="elevation-12" style="min-width: 60vw;">
              <v-toolbar dark color="primary">
                <v-toolbar-title>Login form</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field prepend-icon="person" name="login" label="Login" type="text" v-model="email"></v-text-field>
                  <v-text-field id="password" prepend-icon="lock" name="password" label="Password" type="password" v-model="password"></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary"
                       v-on:click="login"
                       :loading="loading"
                       :disabled="loading">
                  Login
                </v-btn>
              </v-card-actions>
            </v-card>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app'

export default {
  name: 'LoginView',
  data: function () {
    return {
      email: '',
      password: '',
      loader: null,
      loading: false,
      drawer: null

    }
  },
  watch: {
    loader () {
      const l = this.loader
      this[l] = !this[l]

      setTimeout(() => (this[l] = false), 3000)

      this.loader = null
    }
  },
  methods: {
    login: function (e) {
      this.loading = 'loading'
      this.email = this.email.trim()
      this.password = this.password.trim()
      if (this.email !== '' && this.password !== '') {
        firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password)
          .then(
            () => {
              this.$router.push({ path: '/' })
              location.reload()
            },
            err => {
              if (err.message) {
                alert('Unable to log you in. Please check your username and password are correct')
                this.loading = false
              }
            }
          )
      } else {
        this.loading = false
        alert('Please fill out both fields')
      }
      e.preventDefault()
    }
  }
}
</script>

<style scoped>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
