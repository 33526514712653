<template>
  <div id="social-links-dashboard">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-layout row>
            <v-flex xs12 sm6 offset-sm3>
              <v-card>
                <v-list subheader>
                  <v-subheader>Social Links</v-subheader>
                  <social-link-list-tile v-for="social_link in social_links" v-bind:social_link_id="social_link.social_link_id"
                                     v-bind:key="social_link.social_link_id"
                                     v-bind:human_id="social_link.human_id"
                                     v-bind:social_link_name="social_link.social_link_name"></social-link-list-tile>
                </v-list>
                <v-divider></v-divider>
                <v-list subheader>
                  <v-container>
                    <v-btn block color="primary" to="/social-links/add">Add a new social link</v-btn>
                  </v-container>
                </v-list>
              </v-card>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-container>
    </v-main>
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-layout row>
            <v-flex xs12 sm6 offset-sm3>
              <v-card>
                <v-list subheader>
                  <v-subheader>Unapproved Social Links</v-subheader>
                  <social-link-list-tile v-for="social_link in unapproved_social_links" v-bind:social_link_id="social_link.social_link_id"
                                         v-bind:key="social_link.social_link_id"
                                         v-bind:human_id="social_link.human_id"
                                         v-bind:social_link_name="social_link.social_link_name"></social-link-list-tile>
                </v-list>
              </v-card>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import db from '../config/firebaseInit'
import SocialLinkListTile from '../components/SocialLinkListTile'

export default {
  name: 'SocialLinkDashboard',
  components: { SocialLinkListTile },
  data () {
    return {
      social_links: [],
      unapproved_social_links: []
    }
  },
  created () {
    db
      .collection('social_links')
      .orderBy('added')
      .get()
      .then(querySnapshot => {
        let approvedCounter = 1
        let unapprovedCounter = 1
        querySnapshot.forEach(doc => {
          const data = {
            id: doc.id,
            social_link_id: doc.data().social_link_id,
            social_link_name: doc.data().social_link_name,
            social_link_url: doc.data().social_link_url,
            social_link_icon: doc.data().social_link_icon,
            human_id: approvedCounter,
            approved: doc.data().approved
          }
          if (data.approved) {
            data.human_id = approvedCounter
            this.social_links.push(data)
            approvedCounter++
          } else {
            data.human_id = unapprovedCounter
            this.unapproved_social_links.push(data)
            unapprovedCounter++
          }
        })
      })
  }
}
</script>

<style scoped>
  a {
    color: #FFF;
  }

  a:hover {
    color: #00F
  }
</style>
