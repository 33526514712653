<template>
  <div>
    <v-list-item @click.stop="left = !left">
      <v-list-item-action>
        <v-icon>person</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          {{currentUser}}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-group prepend-icon="dashboard">
      <template v-slot:activator>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              Projects
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-list-item @click.stop="left = !left" v-on:click="routeToProjectsDashboard">
        <v-list-item-action>
          <v-icon>dashboard</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Projects Dashboard
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click.stop="left = !left" v-on:click="routeToAddNewProject">
        <v-list-item-action>
          <v-icon>add</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Add Project
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>
    <v-list-group prepend-icon="dashboard">
      <template v-slot:activator>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              Social Links
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-list-item @click.stop="left = !left" v-on:click="routeToSocialLinksDashboard">
        <v-list-item-action>
          <v-icon>dashboard</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Social Links Dashboard
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click.stop="left = !left" v-on:click="routeToSocialLinksAdd">
        <v-list-item-action>
          <v-icon>add</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Add Social Link
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>
    <v-list-item @click.stop="left = !left" v-on:click="routeToReleaseStats">
      <v-list-item-action>
        <v-icon>bar_chart</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          Project Release Stats
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-group prepend-icon="dashboard">
      <template v-slot:activator>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              Short Links
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-list-item @click.stop="left = !left" v-on:click="routeToShortLinks">
        <v-list-item-action>
          <v-icon>dashboard</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Short Links Dashboard
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click.stop="left = !left" v-on:click="routeToAddNewProject">
        <v-list-item-action>
          <v-icon>add</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            New short link
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>
    <v-list-item @click.stop="left = !left" v-on:click="logout">
      <v-list-item-action>
        <v-icon>exit_to_app</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          Logout
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>
<script>
export default {
  name: 'LoggedInSideNav',
  props: {
    currentUser: {},
    left: {},
    logout: {},
    routeToAddNewProject: {},
    routeToProjectsDashboard: {},
    routeToReleaseStats: {},
    routeToShortLinks: {},
    routeToSocialLinksAdd: {},
    routeToSocialLinksDashboard: {}
  }
}
</script>
