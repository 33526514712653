<template>
  <div id="home">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-layout row>
            <v-flex xs12 sm6 offset-sm3>
              <v-card>
                <v-list subheader>
                  <v-subheader>Euan Riggans Website Administration Panel Home</v-subheader>
                  <v-list-item
                    to="/projects"
                  >
                    <v-list-item-avatar>
                      <v-icon>dashboard</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>Projects Dashboard</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-icon>arrow_right_alt</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item
                    to="/social-links"
                  >
                    <v-list-item-avatar>
                      <v-icon>dashboard</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>Social Links Dashboard</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-icon>arrow_right_alt</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item
                    to="/release-stats"
                  >
                    <v-list-item-avatar>
                      <v-icon>bar_chart</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>Project Release Stats</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-icon>arrow_right_alt</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script>
export default {
  name: 'HomeViewHome'
}
</script>

<style scoped>

</style>
