<template>
  <div id="edit-social-link">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-card
            class="mx-auto"
            style="min-width: 60vw;"
          >
            <v-card-title primary-title>
              <div>
                <h3 class="headline mb-0">Edit Social Link</h3>
              </div>
            </v-card-title>
            <v-form
              ref="form"
              class="pa-3 pt-4"
            >
              <v-text-field
                v-model="social_link_name"
                filled
                label="Name"
                type="text"
              ></v-text-field>
              <v-text-field
                v-model="social_link_url"
                auto-grow
                filled
                label="URL"
              ></v-text-field>
              <v-text-field
                v-model="social_link_icon"
                auto-grow
                filled
                label="Icon"
              ></v-text-field>
              <v-select
                :items="approved_options"
                v-model="approved"
                filled
                label="Approved"
              ></v-select>
            </v-form>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                color="primary"
                v-on:click="updateSocialLink"
              >Submit Edited Social Icon
              </v-btn>
              <v-btn
                color="primary"
                text
                v-bind:to="{ name: 'view-social-link', params: { social_link_id: social_link_id }}"
              >Back
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import db from '../config/firebaseInit'

export default {
  name: 'edit-social-link',
  data () {
    return {
      social_link_id: null,
      social_link_name: null,
      social_link_url: null,
      social_link_icon: null,
      approved: null,
      approved_options: [
        true,
        false
      ]
    }
  },
  beforeRouteEnter (to, from, next) {
    db.collection('social_links').where('social_link_id', '==', to.params.social_link_id).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        next(vm => {
          vm.social_link_id = doc.data().social_link_id
          vm.social_link_name = doc.data().social_link_name
          vm.social_link_url = doc.data().social_link_url
          vm.social_link_icon = doc.data().social_link_icon
          vm.approved = doc.data().approved
        })
      })
    })
  },
  watch: {
    '$route': 'fetchData'
  },
  methods: {
    fetchData () {
      db.collection('social_links').where('social_link_id', '==', this.$route.params.social_link_id).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.social_link_id = doc.data().social_link_id
          this.social_link_name = doc.data().social_link_name
          this.social_link_url = doc.data().social_link_url
          this.social_link_icon = doc.data().social_link_icon
          this.approved = doc.data().approved
        })
      })
    },
    updateSocialLink () {
      if (this.social_link_name === null || this.social_link_url === null || this.social_link_icon === null) {
        alert('Please fill out the required fields: "Name", "URL" and "Icon"')
        return
      }
      db.collection('social_links').where('social_link_id', '==', this.$route.params.social_link_id).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.update({
            social_link_id: this.social_link_id,
            social_link_name: this.social_link_name,
            social_link_url: this.social_link_url,
            social_link_icon: this.social_link_icon,
            approved: this.approved,
            edited: new Date()
          })
            .then(() => {
              this.$router.push({ name: 'view-social-link', params: { social_link_id: this.social_link_id } })
            })
        })
      })
    }
  }
}
</script>
