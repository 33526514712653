<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div id="nav" class="nav-wrapper">
    <v-app-bar
      color="primary"
      app
      clipped-left
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{title}}</v-toolbar-title>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      clipped
      app
    >
      <v-list>
        <v-list-item @click.stop="left = !left" v-if="isLoggedIn" v-on:click="routeToHome">
          <v-list-item-action>
            <v-icon>home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Euan Riggans Website
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click.stop="left = !left" v-if="!isLoggedIn" v-on:click="routeToLogin">
          <v-list-item-action>
            <v-icon>home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Euan Riggans Website
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <LoggedInSideNav v-if="isLoggedIn" :current-user="currentUser" :left="left" :logout="logout"
                      :route-to-add="routeToAdd" :route-to-projects-dashboard="routeToProjectsDashboard"
                      :route-to-release-stats="routeToReleaseStats" :route-to-social-links-add="routeToSocialLinksAdd"
                      :route-to-social-links-dashboard="routeToSocialLinksDashboard" :route-to-short-links="routeToShortLinks"/>
        <LoggedOutSideNav v-if="!isLoggedIn" :left="left" :route-to-login="routeToLogin"/>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app'
import LoggedInSideNav from './LoggedInSideNav'
import LoggedOutSideNav from './LoggedOutSideNav'

require('firebase/auth')

export default {
  name: 'AppNavbar',
  components: { LoggedOutSideNav, LoggedInSideNav },
  data () {
    return {
      isLoggedIn: false,
      currentUser: false,
      drawer: null,
      left: false
    }
  },
  props: {
    'title': {
      default: 'Euan Riggans Website',
      type: String
    }
  },
  created () {
    if (firebase.auth().currentUser) {
      this.isLoggedIn = true
      this.currentUser = firebase.auth().currentUser.email
      // firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
      //   console.log(idToken)
      // }).catch(function (error) {
      //   console.log(error)
      // })
    }
  },
  methods: {
    routeToHome: function () {
      this.$router.push({ path: '/' })
    },
    routeToProjectsDashboard: function () {
      this.$router.push({ path: '/projects' })
    },
    routeToSocialLinksDashboard: function () {
      this.$router.push({ path: '/social-links' })
    },
    routeToAdd: function () {
      this.$router.push({ path: '/projects/add' })
    },
    routeToSocialLinksAdd: function () {
      this.$router.push({ path: '/social-links/add' })
    },
    routeToLogin: function () {
      this.$router.push({ path: '/login' })
    },
    routeToReleaseStats: function () {
      this.$router.push({ path: '/release-stats' })
    },
    routeToShortLinks: function () {
      this.$router.push({ path: '/short-links' })
    },
    logout: function () {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.push({ path: '/login' })
          location.reload()
        })
    }
  }
}
</script>

<style scoped>
  .nav-wrapper {
    margin-bottom: 20px;
  }
</style>
