<template>
  <div id="release-stats">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-card
            class="mx-auto"
            style="min-width: 60vw;"
          >
            <v-card-title primary-title>
              <div>
                <h3 class="headline mb-0">Release Stats (Github)</h3>
              </div>
            </v-card-title>
            <v-form
              ref="form"
              class="pa-3 pt-4"
            >
              <v-select
                :items="release_tags"
                v-model="selected_release"
                filled
                @change="onReleaseSelect"
                label="Please select a release"
              ></v-select>
              <v-text-field
                v-model="active_name"
                filled
                disabled
                label="Release Name"
                type="text"></v-text-field>
              <v-select
                :items="release_assets"
                v-model="selected_release_asset"
                filled
                @change="onAssetSelect"
                label="Please select a release asset"
              ></v-select>
              <v-text-field
                v-model="asset_downloads"
                filled
                disabled
                label="Asset Downloads"
                type="text"></v-text-field>
              <v-text-field
                v-model="total_release_downloads"
                filled
                disabled
                label="Total Release Downloads"
                type="text"></v-text-field>
            </v-form>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn text color="info" to="/">Home</v-btn>
            </v-card-actions>
          </v-card>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  name: 'ReleaseStats',
  data () {
    return {
      releases: [],
      release_tags: [],
      selected_release: null,
      active_name: 'No Release Selected',
      release_assets: [],
      selected_release_asset: null,
      asset_downloads: null,
      total_release_downloads: null
    }
  },
  async mounted () {
    await axios
      .get('https://api.github.com/repos/EuanRiggans/BulkURLOpener/releases')
      .then((response) => {
        const parsed = JSON.parse(JSON.stringify(response.data))
        let releaseObj = {
          tag: null,
          name: null,
          assets: [],
          assets_url: null
        }
        let assetStats = {
          name: null,
          downloads: null
        }
        for (let release in parsed) {
          releaseObj = {
            tag: null,
            name: null,
            assets: [],
            assets_url: null
          }
          releaseObj.tag = parsed[release].tag_name
          releaseObj.name = parsed[release].name
          releaseObj.assets_url = parsed[release].assets_url
          for (let asset in parsed[release].assets) {
            assetStats = {
              name: null,
              downloads: null
            }
            assetStats.name = parsed[release].assets[asset].name
            assetStats.downloads = parsed[release].assets[asset].download_count
            releaseObj.assets.push(assetStats)
          }
          this.releases.push(releaseObj)
          this.release_tags.push(releaseObj.tag)
        }
      })
  },
  methods: {
    onReleaseSelect () {
      let releaseObj = null
      this.release_assets = []
      for (let release in this.parseReleaseObj(this.releases)) {
        if (this.parseReleaseObj(this.releases[release]).tag === this.selected_release) {
          releaseObj = this.parseReleaseObj(this.releases[release])
        }
      }
      this.active_name = releaseObj.name
      let totalDownloads = 0
      for (let asset of releaseObj.assets) {
        this.release_assets.push(asset.name)
        totalDownloads += asset.downloads
      }
      this.total_release_downloads = totalDownloads
    },
    onAssetSelect () {
      let releaseObj = null
      for (let release in this.parseReleaseObj(this.releases)) {
        if (this.parseReleaseObj(this.releases[release]).tag === this.selected_release) {
          releaseObj = this.parseReleaseObj(this.releases[release])
        }
      }
      for (let asset of releaseObj.assets) {
        if (asset.name === this.selected_release_asset) {
          this.asset_downloads = asset.downloads
        }
      }
    },
    parseReleaseObj (obj) {
      return JSON.parse(JSON.stringify(obj))
    }
  }
}
</script>

<style scoped>

</style>
