<template>
  <div id="app">
    <v-app>
      <Navbar title="Euan Riggans Website Admin Panel" />
      <div class="container">
        <router-view/>
      </div>
    </v-app>
  </div>
</template>

<script>
import Navbar from './components/Navbar'
export default {
  name: 'app',
  components: {
    Navbar
  }
}
</script>

<style>
  nav {
    margin-bottom: 10px;
  }
</style>
