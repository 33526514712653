<template>
  <div id="projects">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-layout row>
            <v-flex xs12 sm6 offset-sm3>
              <v-card>
                <v-list subheader>
                  <v-subheader>Projects</v-subheader>
                  <project-list-tile v-for="project in projects" v-bind:project_id="project.project_id"
                                     v-bind:key="project.project_id"
                                     v-bind:human_id="project.human_id"
                                     v-bind:project_name="project.name"></project-list-tile>
                </v-list>
                <v-divider></v-divider>
                <v-list subheader>
                  <v-container>
                    <v-btn block color="primary" to="/projects/add">Add a new project</v-btn>
                  </v-container>
                </v-list>
              </v-card>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-container>
    </v-main>
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-layout row>
            <v-flex xs12 sm6 offset-sm3>
              <v-card>
                <v-list subheader>
                  <v-subheader>Unapproved Projects</v-subheader>
                  <project-list-tile v-for="project in unapproved_projects" v-bind:project_id="project.project_id"
                                     v-bind:key="project.project_id"
                                     v-bind:human_id="project.human_id"
                                     v-bind:project_name="project.name"></project-list-tile>
                </v-list>
              </v-card>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import db from '../config/firebaseInit'
import ProjectListTile from '../components/ProjectListTile'

export default {
  name: 'ProjectsDashboard',
  components: { ProjectListTile },
  data () {
    return {
      projects: [],
      unapproved_projects: [],
      loading: true
    }
  },
  created () {
    db
      .collection('projects')
      .orderBy('added')
      .get()
      .then(querySnapshot => {
        this.loading = false
        let approvedCounter = 1
        let unapprovedCounter = 1
        querySnapshot.forEach(doc => {
          const data = {
            id: doc.id,
            project_id: doc.data().project_id,
            name: doc.data().name,
            project_description: doc.data().project_description,
            human_id: approvedCounter,
            approved: doc.data().approved
          }
          if (data.approved) {
            data.human_id = approvedCounter
            this.projects.push(data)
            approvedCounter++
          } else {
            data.human_id = unapprovedCounter
            this.unapproved_projects.push(data)
            unapprovedCounter++
          }
        })
      })
  }
}
</script>

<style scoped>
  a {
    color: #FFF;
  }

  a:hover {
    color: #00F
  }
</style>
