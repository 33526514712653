import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home'
import ProjectsDashboard from '@/views/ProjectsDashboard'
import AddProject from '@/views/AddProject'
import EditProject from '@/views/EditProject'
import ViewProject from '@/views/ViewProject'
import SocialLinksDashboard from '@/views/SocialLinksDashboard'
import AddSocialLink from '@/views/AddSocialLink'
import EditSocialLink from '@/views/EditSocialLink'
import ViewSocialLink from '@/views/ViewSocialLink'
import Login from '@/views/Login'
import ReleaseStats from '@/views/ReleaseStats'
import ShortLinksDashboard from '@/views/ShortLinksDashboard'
import firebase from 'firebase/compat/app'
import ViewShortLink from './views/ViewShortLink'

Vue.use(Router)

let router = new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/projects/',
      name: 'ProjectsDashboard',
      component: ProjectsDashboard,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        requiresGuest: true
      }
    },
    {
      path: '/projects/add',
      name: 'add-project',
      component: AddProject,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/projects/edit/:project_id',
      name: 'edit-project',
      component: EditProject,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/projects/:project_id',
      name: 'view-project',
      component: ViewProject,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/social-links',
      name: 'social-links',
      component: SocialLinksDashboard,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/social-links/add',
      name: 'add-social-link',
      component: AddSocialLink,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/social-links/view/:social_link_id',
      name: 'view-social-link',
      component: ViewSocialLink,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/social-links/edit/:social_link_id',
      name: 'edit-social-link',
      component: EditSocialLink,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/release-stats',
      name: 'release-stats',
      component: ReleaseStats,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/short-links',
      name: 'short-links',
      component: ShortLinksDashboard,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/short-links/:short_link_id',
      name: 'view-short-link',
      component: ViewShortLink,
      meta: {
        requiresAuth: true
      }
    },
  ]
})

// Nav Guard
router.beforeEach((to, from, next) => {
  // Check for requiresAuth guard
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check if NO logged user
    if (!firebase.auth().currentUser) {
      // Go to login
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      })
    } else {
      // Proceed to route
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresGuest)) {
    // Check if NO logged user
    if (firebase.auth().currentUser) {
      // Go to login
      next({
        path: '/',
        query: {
          redirect: to.fullPath
        }
      })
    } else {
      // Proceed to route
      next()
    }
  } else {
    // Proceed to route
    next()
  }
})

export default router
