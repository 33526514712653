<template>
  <div>
    <v-list-item @click.stop="left = !left" v-on:click="routeToLogin">
      <v-list-item-action>
        <v-icon>lock</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          Login
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>
<script>
export default {
  name: 'LoggedOutSideNav',
  props: {
    left: {},
    routeToLogin: {}
  }
}
</script>
