<template>
  <div id="add-project">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-card
            class="mx-auto"
            style="min-width: 60vw;"
          >
            <v-card-title primary-title>
              <div>
                <h3 class="headline mb-0">Add new project</h3>
              </div>
            </v-card-title>
            <v-form
              ref="form"
              class="pa-3 pt-4"
            >
              <v-text-field
                v-model="name"
                filled
                label="Project Name"
                type="text"
              ></v-text-field>
              <v-textarea
                v-model="project_description"
                auto-grow
                filled
                label="Project Description"
                rows="1"
              ></v-textarea>
              <v-textarea
                v-model="project_image_url"
                auto-grow
                filled
                label="Project Image URL"
                rows="1"
              ></v-textarea>
              <v-btn
                block
                color="primary"
                text
                v-on:click="add_project_link_dialog = true">Add Link
              </v-btn>
              <v-chip-group
                multiple
                column
                active-class="primary--text">
                <v-tooltip
                  top
                  v-for="link in this.project_links"
                  v-bind:key="link.project_url"
                >
                  <template v-slot:activator="{ on }">
                    <v-chip
                      class="mr-2"
                      v-on="on"
                      v-on:click="setActiveLink(link.project_label, link.project_url)"
                      @click.stop="dialog = true"
                    >
                      {{link.project_label}}
                    </v-chip>
                  </template>
                  <span>{{link.project_url}}</span>
                </v-tooltip>
              </v-chip-group>
            </v-form>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                color="primary"
                v-on:click="saveProject"
              >Add Project
              </v-btn>
              <v-btn text color="info" to="/projects">Back</v-btn>
            </v-card-actions>
          </v-card>
        </v-layout>
      </v-container>
    </v-main>
    <v-dialog
      v-model="selected_project_link_dialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline dialog-title">Selected Project Link</v-card-title>

        <v-card-text>
          <p>Label: {{active_project_link.label}}</p>
          <p>URL: {{active_project_link.url}}</p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="danger"
            text
            v-on:click="removeLink(active_project_link.label, active_project_link.url, true)"
          >
            Delete Link
          </v-btn>

          <v-btn
            color="primary"
            text
            @click="selected_project_link_dialog = false"
            :href="active_project_link.url"
            target="_blank"
          >
            Open URL
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="add_project_link_dialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline dialog-title">Add Project Link</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="project_link_label"
            filled
            label="Project Link Label"
            type="text"></v-text-field>
          <v-text-field
            v-model="project_link_url"
            filled
            label="Project Link URL"
            type="text"></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="danger"
            text
            v-on:click="closeAddLinkDialog"
          >
            Close
          </v-btn>

          <v-btn
            color="primary"
            text
            v-on:click="addLink"
            target="_blank"
          >
            Add Link
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import db from '../config/firebaseInit'
  import firebase from 'firebase/compat/app'

  require('firebase/auth')
  export default {
    name: 'add-project',
    data () {
      return {
        project_id: null,
        name: null,
        project_description: null,
        project_image_url: null,
        project_links: [],
        project_link_label: null,
        project_link_url: null,
        selected_project_link_dialog: false,
        add_project_link_dialog: false,
        active_project_link: {
          label: null,
          url: null
        }
      }
    },
    methods: {
      saveProject () {
        if (this.project_name === null || this.project_description === null || this.project_image_url === null) {
          alert('Please fill out the required fields: "Project Name", "Project Description" and "Project Image URL"')
          return
        }
        const ref = db.collection('projects').doc()
        db.collection('projects').add({
          project_id: ref.id,
          name: this.name,
          project_description: this.project_description,
          project_image_url: this.project_image_url,
          project_links: this.project_links,
          approved: false,
          added: new Date(),
          edited: new Date(),
          added_by: firebase.auth().currentUser.email
        })
          .then(() => {
            this.$router.push('/projects')
          })
          .catch(error => {
            alert('Error adding project: ' + error)
          })
      },
      addLink () {
        if (this.project_link_label === null || this.project_link_url === null) {
          alert('You must fill in both "Project Link Label" and "Project Link URL"')
          return
        }
        for (let i = 0; i < this.project_links.length; i++) {
          if (this.project_links[i].project_url === this.project_link_url) {
            alert('That URL is already in use by: ' + this.project_links[i].project_label)
            return
          }
        }
        const toAdd = {
          project_label: this.project_link_label,
          project_url: this.project_link_url
        }
        this.project_links.push(toAdd)
        this.project_link_label = null
        this.project_link_url = null
        alert('New project link successfully added: ' + toAdd.project_label + ' => ' + toAdd.project_url)
        this.add_project_link_dialog = false
      },
      removeLink (label, url, fromDialog = false) {
        if (confirm('Are you sure you wish to remove: ' + label + ' => ' + url)) {
          for (let i = 0; i < this.project_links.length; i++) {
            if (this.project_links[i].project_url === url) {
              this.project_links.splice(i, 1)
              if (fromDialog) {
                this.dialog = false
              }
            }
          }
        }
      },
      closeAddLinkDialog () {
        this.project_link_label = ''
        this.project_link_url = ''
        this.add_project_link_dialog = false
      },
      setActiveLink (label, url) {
        this.active_project_link.label = label
        this.active_project_link.url = url
      }
    }
  }
</script>

<style scoped>

  .dialog-title {
    padding-bottom: 20px;
  }

</style>
