<template>
  <div id="projects">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-layout row>
            <v-flex xs12 sm6 offset-sm3>
              <v-card>
                <v-list subheader>
                  <v-subheader>Short links</v-subheader>
                  <short-link-list-tile v-for="short_link in short_links" v-bind:short_link_id="short_link.id"
                                     v-bind:key="short_link.id"
                                     v-bind:human_id="short_link.human_id"
                                     v-bind:short_link_title="short_link.name"></short-link-list-tile>
                </v-list>
                <v-divider></v-divider>
                <v-list subheader>
                  <v-container>
                    <v-btn block color="primary" to="/short-links/add">Add a new short link</v-btn>
                  </v-container>
                </v-list>
              </v-card>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import ShortLinkListTile from '../components/ShortLinkListTile'
import axios from 'axios'

export default {
  name: 'ProjectsDashboard',
  components: { ShortLinkListTile },
  data () {
    return {
      short_links: [],
      loading: true
    }
  },
  async created () {
    await axios
      .get('https://api.euanriggans.com/rebrandly/get-all/?panel_key=JKM7tTgMGTK8Qq7wAki8pTqmfE6iMe')
      .then((response) => {
        let counter = 0;
        for(let shortlink of response.data) {
          const data = {
            id: shortlink.id,
            domain_id: shortlink.domainId,
            name: shortlink.slashtag,
            human_id: counter
          };
          this.short_links.push(data);
          counter++;
        }
      })
  }
}
</script>

<style scoped>
  a {
    color: #FFF;
  }

  a:hover {
    color: #00F
  }
</style>
