<template>
  <div id="add-social-link">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-card
            class="mx-auto"
            style="min-width: 60vw;"
          >
            <v-card-title primary-title>
              <div>
                <h3 class="headline mb-0">Add new social link</h3>
              </div>
            </v-card-title>
            <v-form
              ref="form"
              class="pa-3 pt-4"
            >
              <v-text-field
                v-model="social_link_name"
                filled
                label="Social Link Name"
                type="text"
              ></v-text-field>
              <v-text-field
                v-model="social_link_url"
                filled
                label="Social Link URL"
                type="text"
              ></v-text-field>
              <v-text-field
                v-model="social_link_icon"
                auto-grow
                filled
                label="Social Icon"
              ></v-text-field>
            </v-form>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                color="primary"
                v-on:click="saveLink"
              >Add Social Link
              </v-btn>
              <v-btn text color="info" to="/social-links">Back</v-btn>
            </v-card-actions>
          </v-card>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import db from '../config/firebaseInit'
import firebase from 'firebase/compat/app'

require('firebase/auth')
export default {
  name: 'add-social-link',
  data () {
    return {
      social_link_id: null,
      social_link_name: null,
      social_link_url: null,
      social_link_icon: null
    }
  },
  methods: {
    saveLink () {
      if (this.social_link_url === null || this.social_link_icon === null) {
        alert('Please fill out the required fields: "Social Link URL" and "Social Link Icon"')
        return
      }
      const ref = db.collection('social_links').doc()
      db.collection('social_links').add({
        social_link_id: ref.id,
        social_link_name: this.social_link_name,
        social_link_url: this.social_link_url,
        social_link_icon: this.social_link_icon,
        approved: false,
        added: new Date(),
        edited: new Date(),
        added_by: firebase.auth().currentUser.email
      })
        .then(() => {
          this.$router.push('/social-links')
        })
        .catch(error => {
          alert("Error adding social link: " + error)
        })
    }
  }
}
</script>
