<template>
  <div id="view-social-link">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-card
            class="mx-auto"
            style="min-width: 60vw;"
          >
            <v-card-title primary-title>
              <div>
                <h3 class="headline mb-0">Social Link</h3>
              </div>
            </v-card-title>
            <v-form
              ref="form"
              class="pa-3 pt-4"
            >
              <v-text-field
                v-model="social_link_id"
                filled
                label="Id"
                type="text"
                disabled
              ></v-text-field>
              <v-text-field
                v-model="social_link_name"
                filled
                label="Name"
                type="text"
                disabled
              ></v-text-field>
              <v-textarea
                v-model="social_link_url"
                auto-grow
                filled
                label="URL"
                rows="1"
                disabled
              ></v-textarea>
              <v-text-field
                v-model="social_link_icon"
                auto-grow
                filled
                label="Icon"
                disabled
              ></v-text-field>
              <v-text-field
                v-model="approved"
                filled
                label="Approved"
                type="text"
                disabled
              ></v-text-field>
              <v-text-field
                v-model="added"
                filled
                label="Added Date"
                type="text"
                disabled
              ></v-text-field>
              <v-text-field
                v-model="added_by"
                filled
                label="Added By"
                type="text"
                disabled
              ></v-text-field>
              <v-text-field
                v-model="edited"
                filled
                label="Last Edited"
                type="text"
                disabled
              ></v-text-field>
            </v-form>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="primary" v-bind:to="{ name: 'edit-social-link', params: { social_link_id: social_link_id }}">Edit
              </v-btn>
              <v-btn color="info" to="/social-links">Back
              </v-btn>
              <v-btn color="danger" v-on:click="deleteSocialLink">Delete</v-btn>
            </v-card-actions>
          </v-card>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import db from '../config/firebaseInit'

export default {
  name: 'view-social-link',
  data () {
    return {
      social_link_id: null,
      social_link_name: null,
      social_link_url: null,
      social_link_icon: null,
      approved: null,
      added: null,
      edited: null,
      added_by: null
    }
  },
  beforeRouteEnter (to, from, next) {
    db
      .collection('social_links')
      .where('social_link_id', '==', to.params.social_link_id)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          next(vm => {
            vm.social_link_id = doc.data().social_link_id
            vm.social_link_name = doc.data().social_link_name
            vm.social_link_url = doc.data().social_link_url
            vm.social_link_icon = doc.data().social_link_icon
            vm.approved = doc.data().approved
            vm.added = doc.data().added.toDate().toDateString()
            vm.edited = doc.data().edited.toDate().toDateString()
            vm.added_by = doc.data().added_by
          })
        })
      })
  },
  watch: {
    $route: 'fetchData'
  },
  methods: {
    fetchData () {
      db
        .collection('social_links')
        .where('social_link_id', '==', this.$route.params.social_link_id)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.social_link_id = doc.data().social_link_id
            this.social_link_name = doc.data().social_link_name
            this.social_link_url = doc.data().social_link_url
            this.social_link_icon = doc.data().social_link_icon
            this.approved = doc.data().approved
            this.added = doc.data().added.toDate().toDateString()
            this.edited = doc.data().edited.toDate().toDateString()
            this.added_by = doc.data().added_by
          })
        })
    },
    deleteSocialLink () {
      if (confirm('Are you sure?')) {
        db
          .collection('social_links')
          .where('social_link_id', '==', this.$route.params.social_link_id)
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              doc.ref.delete()
              this.$router.push('/social-links')
            })
          })
      }
    }
  }
}
</script>
